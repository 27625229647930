function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import DOMPurify from 'dompurify';
import { RecommendationComponent } from '../common/recommendation-component';
import { getTmsInstance } from 'integration-web-core--socle/js/assets/commons/_tms';
export default class RecommendationHistory extends RecommendationComponent {
  async init() {
    await this.loadHistoryAsync();
  }
  async loadHistoryAsync() {
    const historyProductList = this.getHistoryFromLocalStorage();
    if (historyProductList.length === 0) {
      this.section.remove();
      return;
    }
    const html = await this.fetchHtmlAsync(historyProductList);
    if (html === null || this.sectionIsEmpty(html)) {
      this.section.remove();
      return;
    }
    this.renderHtml(html);
    lm.lazyload.observe();
    this.initCarousel();
    this.handleClearHistory();
    getTmsInstance().pushFragment([], false);
  }
  sectionIsEmpty(html) {
    // Remove HTML comments
    const cleanedHtml = String(html).replace(/<!--.*?-->/gs, '').trim();
    return cleanedHtml === '<div class="kobi-fragment"></div>';
  }
  getHistoryFromLocalStorage() {
    const currentRef = document.querySelector('.js-product-ref') && document.querySelector('.js-product-ref').innerHTML;
    return lm.localStorageLM.get('historyNavigation').split(',').filter(item => item !== currentRef);
  }
  async fetchHtmlAsync(historyProductList) {
    const historyProductsJoined = historyProductList.join(',');
    const {
      displayAtc,
      zoneId
    } = this.section.dataset;
    const url = new URL('/services/showRecommendationHistory', window.location.origin);
    url.searchParams.set('displayAtc', encodeURIComponent(displayAtc));
    url.searchParams.set('products', encodeURIComponent(historyProductsJoined));
    url.searchParams.set('zoneId', encodeURIComponent(zoneId));
    const response = await fetch(url);
    const status = response.status;
    if (status !== 200) {
      return null;
    }
    return await response.text();
  }
  renderHtml(html) {
    this.section.innerHTML = DOMPurify.sanitize(html, {
      ADD_TAGS: ['script', 'use']
    });
  }

  // DELETE NAVIGATION HISTORY (in localstorage)
  handleClearHistory() {
    const button = this.section.querySelector('.js-history-clear');
    button.addEventListener('click', () => {
      lm.localStorageLM.delete('historyNavigation');
      this.section.remove();
    });
  }
}
_defineProperty(RecommendationHistory, "selector", '.recommendation--history');
_defineProperty(RecommendationHistory, "lazyLoadable", true);
lm.DOMReady(() => RecommendationHistory.setupOnceDomIsReady());